import React, {createContext, useContext, useState} from "react";

interface ErrorContextInterface {
    hasError: boolean,
    errorMessage: string,
    myError: any | null,
    setContextError: (errorMessage: string, myError: Error | null) => void,
    setContextErrorDone: () => void
}

const ErrorContext = createContext<ErrorContextInterface>({
    hasError: false,
    errorMessage: "",
    myError: null,
    setContextError: (errorMessage: string, myError: any | null) => {
    },
    setContextErrorDone: () => {
    },
});

export const ErrorProvider: React.FC<object> = props => {
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [myError, setMyError] = useState<any | null>(null);

    const setContextError = (errorMessage: string, myError: any | null) => {
        setErrorMessage("エラーが発生しました: " + errorMessage);
        setMyError(myError);
        setHasError(true);
    };

    const setContextErrorDone = () => {
        setErrorMessage("");
        setMyError(null);
        setHasError(false);
    };

    return (
        <ErrorContext.Provider
        value={{hasError, errorMessage, myError, setContextError, setContextErrorDone}}
        {...props}
            />
    );
};

export const useMyError = () => {
    return useContext(ErrorContext)
}
