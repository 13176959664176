import axios from 'axios';
import { Axios, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { ErrorOption, FieldPath, FieldValues } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useMyError } from '../providers/ErrorContext';

type APIResult = {
    api: Axios;
    apiRequest: (
        method: "get" | "post" | "delete",
        url: string,
        opt?: {
            params?: any,
            data?: any,
            pathParams?: { [key: string]: string },
            successHandler?: (data: any) => void
        }
    ) => Promise<any>;
    setValidationError: <T extends FieldValues>(
        e: AxiosError<any, any>,
        data: T,
        setErrorFunction: (
            name: FieldPath<T>,
            error: ErrorOption,
            options?: { shouldFocus: boolean; } | undefined
        ) => void
    ) => void;
};

export const useAPI = (): APIResult => {
    const navigate = useNavigate();
    const { setContextError, setContextErrorDone } = useMyError();

    const api = axios.create({
        withCredentials: true
    });

    const apiRequest =
        async (
            method: "get" | "post" | "delete",
            url: string,
            opt?:
                {
                    params?: any,
                    data?: any,
                    pathParams?: any,
                    successHandler?: (data: any) => void
                }
        ) => {
            let request_url = url.toString();

            if (opt?.pathParams) { // パスパラメーターを書き換え
                let pathParams = opt?.pathParams;
                let paths = Object.keys(pathParams);
                paths.forEach((p) => {
                    request_url = request_url.replace(new RegExp('{' + p + '}', 'g'), pathParams[p]);
                });
            }
            let config: AxiosRequestConfig = {
                method: method,
                url: request_url,
                headers: api.defaults.headers.common
            }
            if (opt?.params)
                config.params = opt?.params;
            if (opt?.data)
                config.data = opt?.data;

            return api.request(config).then(
                (res: AxiosResponse) => {
                    let d: any = res.data;
                    setContextErrorDone();

                    if (opt?.successHandler)
                        opt?.successHandler(d);

                    return Promise.resolve(d);
                }
            ).catch((e) => {
                if (e.response?.status === 404) { // not found
                    setContextError("データがありません", null);
                    return;
                }
                if (e.response?.status === 419) { // CSRF token mismatch.
                    try {
                        setContextError("セッションを復帰します", null);
                        window.location.reload();
                    } catch (e: any) {
                        setContextError(e.response?.data?.message || e.data?.message || e.message || e.toString(), e);
                        navigate('/logout');
                    }
                    return;
                }
                if (e.response?.status === 401) { // Unauthorized 有効な認証資格が不足している場合
                    setContextError(e.response?.data?.message || e.data?.message || e.message || e.toString(), e);
                    navigate('/logout');
                } else {
                    setContextError(e.response?.data?.message || e.data?.message || e.message || e.toString(), e);
                }
            });
        }

    function setValidationError<T extends FieldValues>(
        e: AxiosError<any>, data: T,
        setErrorFunction: (name: FieldPath<T>, error: ErrorOption, options?: { shouldFocus: boolean }
        ) => void) {
        // サーバー側のエラーのセット
        type ValidationError = { [key: string]: string[] };

        if (e === null) return;
        let errs: ValidationError = e.response?.data?.errors as ValidationError;
        if (errs === undefined) return;

        Object.keys(errs).forEach((key: string) => {
            let path = key.replace(/\.[^.]+$/, '.id'); // idは必ず値がある
            if (key in data
                ||
                path.split('.').reduce((p, c) => (p && p[c]) || null, data) !== null // パスがあるかどうか
            ) {
                setErrorFunction(
                    key as FieldPath<T>,
                    { type: "manual", message: errs[key][0] }
                );
            } else {
                console.debug("存在しないキーが返却されました:" + key);
            }
        });
    }

    return {
        api,
        apiRequest,
        setValidationError
    };
};

