import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import {
    Box, Container, Typography, Grid, Alert,
    Button, TextField, CssBaseline
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useAPI } from '../lib/useAPI';
import { useMyError } from '../providers/ErrorContext';
import { useSanctum } from 'react-sanctum';
import { MyFooter } from '../components/MyFooter';
import { Link } from 'react-router-dom';
import "./common.css";
import styles from "./Login.module.css";
import { useCookies } from 'react-cookie';

type FormProps = {
    email: string;
    password: string;
    remember: boolean;
};

export const Login = () => {
    const [, setCookie] = useCookies(['org']);
    const { signIn, signOut } = useSanctum()
    const { setValidationError, apiRequest } = useAPI();
    const location = useLocation();
    const { hasError, errorMessage, myError, setContextError, setContextErrorDone } = useMyError();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (!hasError) return;

        setValidationError<FormProps>(myError, getValues(), setError);

        // eslint-disable-next-line 
    }, [hasError]);

    const {
        handleSubmit,
        control,
        getValues,
        setError
    } = useForm<FormProps>({
        mode: "onBlur",
        criteriaMode: "all",
        shouldFocusError: false,
        shouldUnregister: false,
    });

    const LogoBox = styled(Box)(({ theme }) => ({
        '& img': {
            maxWidth: '100%',
            height: 'auto',
        }
    }));

    const onSubmit: SubmitHandler<FormProps> = (data) => {
        setContextErrorDone();

        signIn(data.email, data.password, data.remember)
            .then(() => {
                apiRequest('get', '/api/user/me',
                    {
                        params: {},
                        successHandler: (res) => {
                            if (res.need_reset) {
                                window.location.href = '/setting/password/forgot';
                                signOut();
                            } else {
                                setCookie('org', res.organization?.id);
                                const url = location.pathname + location.search + location.hash;
                                navigate(url);
                            }
                        }
                    });
            })
            .catch((e: any) => {
                setContextError(e.response?.data?.message ?? e.data?.message ?? e.message ?? e.toString(), e)
            });

    };

    return (
        <>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <LogoBox
                    sx={{ maxWidth: { xs: 200, textAlign: 'center', md: 'clamp(200px, 50% ,50vw)' } }}
                    className={styles.logoContainer}
                >
                    <Link
                        to="/"
                    >
                        <img src="/images/yure_logo.svg" alt="ゆれクラウド" />
                    </Link>
                </LogoBox>
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography component="h1" variant="h5">
                        ログイン
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, flexGrow: 1 }}>
                        {errorMessage !== "" && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "必須項目です",
                                        maxLength: {
                                            value: 255,
                                            message: "255文字以下で入力してください",
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField {...field}
                                            margin="normal"
                                            fullWidth
                                            label="email"
                                            placeholder="emailを入力してください。"
                                            error={Boolean(error)}
                                            helperText={error?.message}
                                            autoComplete="email"
                                            autoFocus
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "必須項目です",
                                        maxLength: {
                                            value: 255,
                                            message: "255文字以下で入力してください",
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField {...field}
                                            margin="normal"
                                            type="password"
                                            fullWidth
                                            label="password"
                                            placeholder="passwordを入力してください。"
                                            error={Boolean(error)}
                                            helperText={error?.message}
                                            autoComplete="current-password"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
                                <Button type="submit" variant="contained" sx={{ backgroundColor: '#D23B30', '&:hover': { backgroundColor: '#bd352b' } }}>
                                    ログインする
                                </Button>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <a href="/setting/password/forgot" className={styles.linkText}>パスワードを忘れた方はこちら</a>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <MyFooter />
            </Container>
        </>
    );
}

