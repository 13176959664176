import Typography from '@mui/material/Typography';
// import styles from "../pages/Login.module.css";

export const MyFooter = (props: any) => {
    return (
        <div>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'© '}
                {new Date().getFullYear()}
                {' '}
                Tokyo Power Technology.
                {/*
                {' | '}
                <a href="/terms" target="_blank" className={styles.linkText}>利用規約</a>
                */}
            </Typography>

        </div>
    );
}
