import React, { lazy, Suspense } from 'react'
import { Route, Routes } from "react-router-dom";
import { Login } from '../pages/Login'
import { useLocation } from 'react-router-dom'
import { useSanctum } from 'react-sanctum'
import FullScreenCircular from '../components/FullScreenCircular';

const PasswordForgot = lazy(() => import('../pages/Setting/Password/Forgot').then((module) => ({ default: module.PasswordForgot })));
const PasswordReset = lazy(() => import('../pages/Setting/Password/Reset').then((module) => ({ default: module.PasswordReset })));
const ReLogin = lazy(() => import('../pages/ReLogin').then((module) => ({ default: module.ReLogin })));
const Terms = lazy(() => import('../pages/Terms').then((module) => ({ default: module.Terms })));

interface Props {
    children: React.ReactNode[] | React.ReactNode
}

export const AuthGuard: React.FC<Props> = ({ children }) => {
    const { authenticated, signOut } = useSanctum()
    const location = useLocation();

    if (authenticated == null) { // チェック中
        return (<FullScreenCircular />);
    } else if (authenticated === false) {
        // window.history.pushState(null, 'top', '/'); // URLを/にする
        // 上の記述があるとpreviewとcompareのbookmarkからの#の値が引き継げない
        return (
            <Routes>
                <Route path="/setting/password/forgot" element={<Suspense fallback={<FullScreenCircular />}> <PasswordForgot /> </Suspense>} />
                <Route path="/setting/password/reset" element={<Suspense fallback={<FullScreenCircular />}> <PasswordReset /> </Suspense>} />
                <Route path="/relogin" element={<Suspense fallback={<FullScreenCircular />}> <ReLogin /> </Suspense>} />
                <Route path="/terms" element={<Suspense fallback={<FullScreenCircular />}> <Terms /> </Suspense>} />
                <Route path="/*" element={
                    <Login />
                } />
            </Routes>
        )
    } else {
        if (
            location.pathname.startsWith("/setting/password/forgot")
            || location.pathname.startsWith("/setting/password/reset")
            || location.pathname.startsWith("/relogin")
        ) {
            signOut();
        }
        return (
            <>
                {children}
            </>
        )
    }
}
