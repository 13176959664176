import { FC, lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import {
    Box, Container, CssBaseline
} from '@mui/material';
import { useSanctum } from 'react-sanctum'
import { MyHeader, MyHeaderTop, MyFooter } from './components';
import FullScreenCircular from './components/FullScreenCircular';
import { MapProvider } from 'react-map-gl';
import styles from "./App.module.css";
import "./global.css";

const Terms = lazy(() => import('./pages/Terms').then((module) => ({ default: module.Terms })));
const ReLogin = lazy(() => import('./pages/ReLogin').then((module) => ({ default: module.ReLogin })));
const Logout = lazy(() => import('./pages/Logout').then((module) => ({ default: module.Logout })));
const MapIndex = lazy(() => import('./pages/Map/Index').then((module) => ({ default: module.MapIndex })));
const BuildingIndex = lazy(() => import('./pages/Building/Index').then((module) => ({ default: module.BuildingIndex })));
const BuildingEew = lazy(() => import('./pages/Building/Eew').then((module) => ({ default: module.BuildingEew })));
const EarthquakeIndex = lazy(() => import('./pages/Earthquake/Index').then((module) => ({ default: module.EarthquakeIndex })));
const SettingIndex = lazy(() => import('./pages/Setting/Index').then((module) => ({ default: module.SettingIndex })));
const CategoryIndex = lazy(() => import('./pages/Setting/Category/Index').then((module) => ({ default: module.CategoryIndex })));
const CategoryEdit = lazy(() => import('./pages/Setting/Category/Edit').then((module) => ({ default: module.CategoryEdit })));
const GroupIndex = lazy(() => import('./pages/Setting/Group/Index').then((module) => ({ default: module.GroupIndex })));
const GroupEdit = lazy(() => import('./pages/Setting/Group/Edit').then((module) => ({ default: module.GroupEdit })));
const GroupUser = lazy(() => import('./pages/Setting/Group/User').then((module) => ({ default: module.GroupUser })));
const GroupOrganization = lazy(() => import('./pages/Setting/Group/Organization').then((module) => ({ default: module.GroupOrganization })));
const GroupBuilding = lazy(() => import('./pages/Setting/Group/Building').then((module) => ({ default: module.GroupBuilding })));
const SettingBuildingIndex = lazy(() => import('./pages/Setting/Building/Index').then((module) => ({ default: module.SettingBuildingIndex })));
const BuildingEdit = lazy(() => import('./pages/Setting/Building/Edit').then((module) => ({ default: module.BuildingEdit })));
const SensorIndex = lazy(() => import('./pages/Setting/Sensor/Index').then((module) => ({ default: module.SensorIndex })));
const SensorEdit = lazy(() => import('./pages/Setting/Sensor/Edit').then((module) => ({ default: module.SensorEdit })));
const UserIndex = lazy(() => import('./pages/Setting/User/Index').then((module) => ({ default: module.UserIndex })));
const UserEdit = lazy(() => import('./pages/Setting/User/Edit').then((module) => ({ default: module.UserEdit })));
const UserBulk = lazy(() => import('./pages/Setting/User/Bulk').then((module) => ({ default: module.UserBulk })));
const OrganizationIndex = lazy(() => import('./pages/Setting/Organization/Index').then((module) => ({ default: module.OrganizationIndex })));
const OrganizationEdit = lazy(() => import('./pages/Setting/Organization/Edit').then((module) => ({ default: module.OrganizationEdit })));
const LogDownload = lazy(() => import('./pages/Setting/LogDownload').then((module) => ({ default: module.LogDownload })));
const BuildingInfo = lazy(() => import('./pages/Building/Info').then((module) => ({ default: module.BuildingInfo })));
const EewIndex = lazy(() => import('./pages/Eew/Index').then((module) => ({ default: module.EewIndex })));
const PasswordForgot = lazy(() => import('./pages/Setting/Password/Forgot').then((module) => ({ default: module.PasswordForgot })));
const BuildingWave = lazy(() => import('./pages/Building/Wave').then((module) => ({ default: module.BuildingWave })));
const BuildingDetail = lazy(() => import('./pages/Building/Detail').then((module) => ({ default: module.BuildingDetail })));
const BuildingCamera = lazy(() => import('./pages/Building/Camera').then((module) => ({ default: module.BuildingCamera })));
const EnergyLawIndex = lazy(() => import('./pages/Setting/EnergyLaw/Index').then((module) => ({ default: module.EnergyLawIndex })));
const EnergyLawResult = lazy(() => import('./pages/Setting/EnergyLaw/Result').then((module) => ({ default: module.EnergyLawResult })));
const EnergyLawSetting = lazy(() => import('./pages/Setting/EnergyLaw/Setting').then((module) => ({ default: module.EnergyLawSetting })));
const EnergyLawList = lazy(() => import('./pages/Setting/EnergyLaw/List').then((module) => ({ default: module.EnergyLawList })));
const MaintenanceIndex = lazy(() => import('./pages/Setting/Maintenance/Index').then((module) => ({ default: module.MaintenanceIndex })));
const MaintenanceStore = lazy(() => import('./pages/Setting/Maintenance/Store').then((module) => ({ default: module.MaintenanceStore })));
const MaintenanceKickNotify = lazy(() => import('./pages/Setting/Maintenance/KickNotify').then((module) => ({ default: module.MaintenanceKickNotify })));

const App: FC = () => {
    const location = useLocation();
    const showFooter = location.pathname !== "/" && location.pathname !== "/map";
    const { user } = useSanctum()

    return (
        <Container component="main" maxWidth={false} disableGutters style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <MyHeaderTop />
            <Box className={styles.appBoody}>
                <MyHeader />
                <Routes>
                    <Route path="/earthquake" element={<Suspense fallback={<FullScreenCircular />}> <EarthquakeIndex /> </Suspense>} />
                    <Route path="/eew" element={<Suspense fallback={<FullScreenCircular />}> <EewIndex /> </Suspense>} />
                    <Route path="/building-sensor" element={<Suspense fallback={<FullScreenCircular />}> <BuildingIndex /> </Suspense>} />
                    <Route path="/building-sensor/eew" element={<Suspense fallback={<FullScreenCircular />}> <BuildingEew /> </Suspense>} />
                    <Route path="/s/:eewParam" element={<Suspense fallback={<FullScreenCircular />}> <BuildingEew /> </Suspense>} />
                    <Route path="/building/info" element={<Suspense fallback={<FullScreenCircular />}> <BuildingInfo /> </Suspense>} />
                    <Route path="/building/wave" element={<Suspense fallback={<FullScreenCircular />}> <BuildingWave /> </Suspense>} />
                    <Route path="/building/detail" element={<Suspense fallback={<FullScreenCircular />}> <BuildingDetail /> </Suspense>} />
                    <Route path="/building/camera" element={<Suspense fallback={<FullScreenCircular />}> <BuildingCamera /> </Suspense>} />
                    <Route path="/setting" element={<Suspense fallback={<FullScreenCircular />}> <SettingIndex /> </Suspense>} />
                    <Route path="/setting/user/edit" element={<Suspense fallback={<FullScreenCircular />}> <UserEdit /> </Suspense>} />
                    {user.role !== "user" &&
                        <>
                            <Route path="/setting/category" element={<Suspense fallback={<FullScreenCircular />}> <CategoryIndex /> </Suspense>} />
                            <Route path="/setting/category/edit" element={<Suspense fallback={<FullScreenCircular />}> <CategoryEdit /> </Suspense>} />
                            <Route path="/setting/group" element={<Suspense fallback={<FullScreenCircular />}> <GroupIndex /> </Suspense>} />
                            <Route path="/setting/group/edit" element={<Suspense fallback={<FullScreenCircular />}> <GroupEdit /> </Suspense>} />
                            <Route path="/setting/group/user" element={<Suspense fallback={<FullScreenCircular />}> <GroupUser /> </Suspense>} />
                            <Route path="/setting/group/building" element={<Suspense fallback={<FullScreenCircular />}> <GroupBuilding /> </Suspense>} />
                            <Route path="/setting/group/organization" element={<Suspense fallback={<FullScreenCircular />}> <GroupOrganization /> </Suspense>} />
                            <Route path="/setting/building" element={<Suspense fallback={<FullScreenCircular />}> <SettingBuildingIndex /> </Suspense>} />
                            <Route path="/setting/building/edit" element={<Suspense fallback={<FullScreenCircular />}> <BuildingEdit /> </Suspense>} />
                            <Route path="/setting/sensor" element={<Suspense fallback={<FullScreenCircular />}> <SensorIndex /> </Suspense>} />
                            <Route path="/setting/sensor/edit" element={<Suspense fallback={<FullScreenCircular />}> <SensorEdit /> </Suspense>} />
                            <Route path="/setting/user" element={<Suspense fallback={<FullScreenCircular />}> <UserIndex /> </Suspense>} />
                            <Route path="/setting/user/bulk" element={<Suspense fallback={<FullScreenCircular />}> <UserBulk /> </Suspense>} />
                            <Route path="/setting/organization" element={<Suspense fallback={<FullScreenCircular />}> <OrganizationIndex /> </Suspense>} />
                            <Route path="/setting/organization/edit" element={<Suspense fallback={<FullScreenCircular />}> <OrganizationEdit /> </Suspense>} />
                            <Route path="/setting/maintenance" element={<Suspense fallback={<FullScreenCircular />}> <MaintenanceIndex /> </Suspense>} />
                            <Route path="/setting/maintenance/store" element={<Suspense fallback={<FullScreenCircular />}> <MaintenanceStore /> </Suspense>} />
                            <Route path="/setting/maintenance/kick-notify" element={<Suspense fallback={<FullScreenCircular />}> <MaintenanceKickNotify /> </Suspense>} />
                            <Route path="/setting/logdownload" element={<Suspense fallback={<FullScreenCircular />}> <LogDownload /> </Suspense>} />
                            <Route path="/setting/password/forgot" element={<Suspense fallback={<FullScreenCircular />}> <PasswordForgot /> </Suspense>} />
                            <Route path="/setting/energylaw" element={<Suspense fallback={<FullScreenCircular />}> <EnergyLawIndex /> </Suspense>} />
                            <Route path="/setting/energylaw/result" element={<Suspense fallback={<FullScreenCircular />}> <EnergyLawResult /> </Suspense>} />
                            <Route path="/setting/energylaw/setting" element={<Suspense fallback={<FullScreenCircular />}> <EnergyLawSetting /> </Suspense>} />
                            <Route path="/setting/energylaw/list" element={<Suspense fallback={<FullScreenCircular />}> <EnergyLawList /> </Suspense>} />
                        </>
                    }
                    <Route path="/logout" element={<Suspense fallback={<FullScreenCircular />}> <Logout /> </Suspense>} />
                    <Route path="/relogin" element={<Suspense fallback={<FullScreenCircular />}> <ReLogin /> </Suspense>} />
                    <Route path="/terms" element={<Suspense fallback={<FullScreenCircular />}> <Terms /> </Suspense>} />
                    <Route path="/*" element={<Suspense fallback={<FullScreenCircular />}> <MapProvider> <MapIndex /> </MapProvider></Suspense>} />
                </Routes>
                {showFooter && <MyFooter sx={{ my: 3 }} />}
            </Box>
        </Container>
    )
};

export default App;
