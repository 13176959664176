import { useState } from 'react';
import { styled } from "@mui/material/styles";

import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import WavesIcon from '@mui/icons-material/Waves';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSanctum } from 'react-sanctum'
import styles from "./MyHeader.module.css";
import {
    Box, Drawer, List, ListItem, ListItemText, Divider, IconButton, Button
} from '@mui/material';

const HamburgerBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '3px',
    right: '10px',
}));

const HamburgerButton = styled(IconButton)(({ theme }) => ({
    padding: '3px',
    '& svg': {
        width: '24px',
        height: '28px',
        color: '#ffffff',
    }
}));

export const MyHeaderTop = () => {
    const { authenticated, user } = useSanctum();
    const [open, setopen] = useState(false);
    const toggleOpen = () => {
        setopen(!open);
    }
    const navigate = useNavigate();

    return (
        <>
            {/* PCヘッダ */}
            <div className={styles.myHeaderTop}>
                {authenticated &&
                    <>
                        <Box className={styles.pcHeaderText + " ellipsis400"} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', alignItems: 'top' }}><span className="ellipsis200">{user.name}</span><span>&nbsp;/&nbsp;</span><span className="ellipsis300">{user.organization.name}</span></Box>
                        <Box className={styles.pcHeaderText + " ellipsis150"} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', alignItems: 'top' }}><span className="ellipsis75">{user.name}</span><span>&nbsp;/&nbsp;</span><span className="ellipsis75">{user.organization.name}</span></Box>
                    </>
                }
                <Box className={styles.pcHeaderText} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', alignItems: 'top' }}>
                    <Link to="/logout" className={styles.menuLogoutButton}>
                        <LogoutIcon sx={{ color: { md: '#333333', xs: '#ffffff' }, width: { xs: '20px', md: '24px' }, height: { xs: '20px', md: '24px' } }} />
                        <Button variant="text" sx={{ color: { md: '#333333', xs: '#ffffff' }, m: 0 }} className={styles.menuLogoutButtonText}>ログアウト</Button>
                    </Link>
                </Box>
                <HamburgerBox sx={{ display: { xs: 'block', md: 'none' } }}>
                    <HamburgerButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleOpen}
                    >
                        <MenuIcon />
                    </HamburgerButton>
                </HamburgerBox>
            </div>
            <Drawer sx={{ width: 300, flexShrink: 0 }} anchor="right" open={open} variant="temporary" onClose={toggleOpen}>
                <List>
                    <ListItem button onClick={toggleOpen}>
                        <ListItemIcon>
                            <CloseIcon />
                        </ListItemIcon>
                        <ListItemText primary="閉じる" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button href="" onClick={() => { navigate("/map"); toggleOpen(); }}>
                        <ListItemIcon>
                            <PublicIcon />
                        </ListItemIcon>
                        <ListItemText primary="マップ" />
                    </ListItem>
                    <ListItem button href="" onClick={() => { navigate("/eew"); toggleOpen(); }}>
                        <ListItemIcon>
                            <WavesIcon />
                        </ListItemIcon>
                        <ListItemText primary="地震情報" />
                    </ListItem>
                    <ListItem button href="" onClick={() => { navigate("/building-sensor"); toggleOpen(); }}>
                        <ListItemIcon>
                            <ApartmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="建物地震計" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate("/setting"); toggleOpen(); }}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="設定" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate("/logout"); toggleOpen(); }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="ログアウト" />
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}
