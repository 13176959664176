import { FC } from 'react';
import { useState, useEffect } from 'react';
import { styled } from "@mui/material/styles";
import { Fade, CircularProgress } from '@mui/material';

const CircularContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: '0px',
  left: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10000,
  backgroundColor: 'rgba(252, 251, 251, 0.8)'
}));

const FullScreenCircular: FC = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    return () => {
      setShow(false);
    }
  }, []);

  return (
    <Fade in={show} timeout={500}>
      <CircularContainer>
        <CircularProgress />
      </CircularContainer>
    </Fade>
  );
}

export default FullScreenCircular;