import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './lib/ErrorFallback';
import './index.css';
import { ThemeProvider } from '@mui/material';
import MyGlobalTheme from './theme/MyGlobalTheme';
import App from './App';
import { ErrorProvider } from './providers/ErrorContext';
import { AuthGuard } from './providers/AuthGuard';
import { Sanctum } from 'react-sanctum'
import { CookiesProvider } from 'react-cookie';

const sanctumConfig = {
    apiUrl: window.location.protocol + '//' + window.location.host,
    csrfCookieRoute: 'api/sanctum/csrf-cookie',
    signInRoute: 'api/login',
    signOutRoute: 'api/logout',
    userObjectRoute: 'api/user/me',
    usernameKey: 'email'
}

// msw を起動
/*
if (process.env.NODE_ENV === "development") {
    const { worker } = require("./mocks/browser");
    worker.start({
        onUnhandledRequest: 'bypass' // 未処理のリクエストはモックせずに通す
    });
}
*/
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ThemeProvider theme={MyGlobalTheme}>
                    <ErrorProvider>
                        <Sanctum config={sanctumConfig}>
                            <AuthGuard>
                                <CookiesProvider defaultSetOptions={{ path: '/' }}>
                                    <App />
                                </CookiesProvider>
                            </AuthGuard>
                        </Sanctum>
                    </ErrorProvider>
                </ThemeProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode >
);
