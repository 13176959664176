import { useLocation } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import styles from "./MyHeader.module.css";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    borderBottom: '1px solid #cccccc'
}));

const StyledPCButton = styled(Button)(({ theme }) => ({
    flexDirection: 'column',
    marginRight: '0',
    '& > span': {
        marginLeft: '0',
        marginRight: '0',
        marginBottom: '2px',
        minWidth: '28px'
    },
    '& > span > img': {
        margin: '0 auto',
    },
    '& > span > svg': {
        fontSize: '28px!important',
    }
}));

const LogoBox = styled(Box)(({ theme }) => ({
    '& img': {
        verticalAlign: 'bottom',
        maxWidth: '100%',
        height: 'auto',
    }
}));



export const MyHeader = () => {
    const currentLocation = useLocation();
    const currentCategory: string = currentLocation.pathname.split('/')[1];
    return (
        <>
            <StyledAppBar position="static" sx={{ px: { md: 5, xs: 0 }, justifyContent: 'center' }} className={styles.navAppBar}>
                <Toolbar sx={{ justifyContent: { md: 'center', xs: 'flex-start' }, flex: 1, minHeight: { xs: 40, md: 56 } }} className={styles.navToolBar}>
                    <LogoBox
                        sx={{ pr: 2, maxWidth: { xs: 96, md: 'clamp(96px, 179px ,13vw)' } }}
                        className={styles.logoContainer}
                    >
                        <Link
                            to="/"
                            reloadDocument={true}
                        >
                            <img src="/images/yure_logo.svg" alt="ゆれクラウド" />
                        </Link>
                    </LogoBox>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, height: { xs: 'auto', md: 81 }, justifyContent: 'center' }} className={styles.pcNavButtonBox}>
                        <Link to="/map" className={`${styles.pcNavButtonContainer} ${currentCategory === '' || currentCategory === 'map' ? styles.current : ''}`}>
                            <StyledPCButton sx={{ color: '#000', pr: 3 }} className={styles.pcNavButton}>マップ</StyledPCButton>
                        </Link>
                        <Link to="/eew" className={`${styles.pcNavButtonContainer} ${(currentCategory === 'eew' || currentCategory === 'earthquake') ? styles.current : ''}`}>
                            <StyledPCButton sx={{ color: '#000', pr: 3 }} className={styles.pcNavButton}>地震情報</StyledPCButton>
                        </Link>
                        <Link to="/building-sensor" className={`${styles.pcNavButtonContainer} ${currentCategory === 'building-sensor' || currentCategory === 'building' ? styles.current : ''}`}>
                            <StyledPCButton sx={{ color: '#000', pr: 3 }} className={styles.pcNavButton}>建物地震計</StyledPCButton>
                        </Link>
                        <Link to="/setting" className={`${styles.pcNavButtonContainer} ${currentCategory === 'setting' ? styles.current : ''}`}>
                            <StyledPCButton sx={{ color: '#000', pr: 3 }} className={styles.pcNavButton}>設定　</StyledPCButton>
                        </Link>
                    </Box>

                </Toolbar>
            </StyledAppBar>
        </>
    );
}
