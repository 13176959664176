import { createTheme } from '@mui/material';

const MyGlobalTheme = createTheme({
    palette: {
        primary: {
            main: '#505050',
            contrastText: '#FFFFFF',
        },
        text: { primary: '#000000' },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: [
            'Noto Sans JP',
            '-apple-system',
            'Roboto',
            '"游ゴシック体"',
            '"YuGothic"',
            '"游ゴシック Medium"',
            '"Yu Gothic Medium"',
            'serif',
        ].join(','),
    },
    components: {
        // Name of the component
        MuiCssBaseline: {
            styleOverrides: {
                '.ellipsis75': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '75px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis150': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '150px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis160': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '160px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis170': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '170px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis180': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '180px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis200': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '200px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis300': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '300px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis400': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '400px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis500': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '500px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis600': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '600px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '.ellipsis700': {
                    display: 'inline-block', // または必要に応じて 'block'
                    maxWidth: '700px', // または必要に応じて調整
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.MuiListItemText-primary': { // ListItemText の primary テキストに適用
                        maxWidth: '600px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    '&.MuiListItemText-secondary': { // ListItemText の secondary テキストに適用
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '1rem',
                    lineHeight: '1.2',
                    borderRadius: '3px',
                    boxShadow: 'none',
                    marginLeft: '5px',
                    marginRight: '5px',
                    padding: '15px 15px 14px',
                    textTransform: 'none'
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& textarea': {
                        resize: 'both',
                        overflow: 'auto',
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'black',
                    "&.Mui-focused": {
                        color: 'black', // フォーカス時の色も黒にしたい場合
                    },
                },
            }
        },
    },
});

export default MyGlobalTheme;
