import { FallbackProps } from 'react-error-boundary';
import { FC } from 'react';
import {
    Box, Container, CssBaseline, Grid, Alert, Button
} from '@mui/material';

export const ErrorFallback: FC<FallbackProps> = ({
    error
}) => {
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box sx={{ mt: 1, flexGrow: 1 }}>
                <h2>エラーが発生しました</h2>
                <Alert severity="error">{error.message}</Alert>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6} textAlign="center">
                        <Button href="/" variant="contained">
                            トップへ戻る
                        </Button>
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                        <Button href="/logout" variant="contained">
                            ログアウトする
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
